<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">保护管理相关培训记录</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item
                    label="培训项目名称"
                    prop="PXXMMC"
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.PXXMMC"
                        :disabled="!isEdit"
                        placeholder="请填写培训项目名称"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="培训开始/截止时间"
                    :style="{ width: '80%' }"
                >
                    <el-date-picker
                        v-model="range"
                        :style="{ width: '100%' }"
                        type="daterange"
                        range-separator="至"
                        value-format="yyyy-MM-dd"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :disabled="!isEdit"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                    label="受训人员数量(人)"
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.SXRYSL"
                        :disabled="!isEdit"
                        placeholder="请填写受训人员数量"
                        @input="toNumber($event, 'SXRYSL')"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="培训支出经费(元)"
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.PXZCJF"
                        :disabled="!isEdit"
                        placeholder="请填写培训支出经费"
                        @input="toDecimal($event, 'PXZCJF')"
                        @blur="blurMoney('PXZCJF')"
                        @focus="focusMoney('PXZCJF')"
                    >
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    filters: {
        splitByComma: function(value) {
            return String(value).replace(/\B(?=(\d{3})+$)/g, ",");
        },
    },
    mixins: [infoMixin],
    data() {
        let validatePxxmmc = async (rule, value, callback) => {
            const reg = /^[a-zA-Z0-9\u4e00-\u9fa5()\（\）\、\.\-:\：]+$/;
            const reg2 = /^[^\、\.\-:\：][a-zA-Z0-9\u4e00-\u9fa5()\（\）\、\.\-:\：]+[^\、\.\-:\：]$/;
            if (!value) {
                callback(new Error("请填写培训项目名称"));
            } else if (!reg.test(value.trim())) {
                callback(new Error("项目名称只能包含中英文数字和小括号及顿号"));
            } else if (!reg2.test(value.trim())) {
                callback(new Error("名称不能太短，开头结尾不可以是特殊符号"));
            } else {
                callback();
            }
        };
        return {
            range: "",
            isEdit: true,
            saveButton: true,
            formdata: {
                dataDic: {
                    glycbtid: this.heritageId,
                    PXXMMC: null,
                    PXKSSJ: null,
                    PXJZSJ: null,
                    SXRYSL: null,
                    PXZCJF: null,
                },
                fileids: "",
                itemCode: "203",
                metaJson: [],
            },
            rules: {
                PXXMMC: [
                    {
                        required: true,
                        validator: validatePxxmmc,
                        trigger: ["blur", "change"],
                    },
                ],
                PXKSSJ: [
                    {
                        required: true,
                        message: "请选择开始时间",
                        trigger: ["blur", "change"],
                    },
                ],
                PXJZSJ: [
                    {
                        required: true,
                        message: "请选择培训时间",
                        trigger: ["blur", "change"],
                    },
                ],
            },
            toMoneyProps: ["PXZCJF"],
        };
    },
    watch: {
        range: {
            immediate: true,
            handler: function() {
                if (this.range) {
                    this.formdata.dataDic.PXKSSJ = this.range[0];
                    this.formdata.dataDic.PXJZSJ = this.range[1];
                } else {
                    this.formdata.dataDic.PXKSSJ = "";
                    this.formdata.dataDic.PXJZSJ = "";
                }
            },
        },
        formdata: {
            deep: true,
            handler: function() {
                if (
                    this.formdata.dataDic.PXKSSJ &&
                    this.formdata.dataDic.PXJZSJ
                ) {
                    let startTime = this.formdata.dataDic.PXKSSJ.split(" ")[0];
                    let endTime = this.formdata.dataDic.PXJZSJ.split(" ")[0];
                    this.range = [startTime, endTime];
                }
            },
        },
    },
    mounted() {},
    methods: {},
};
</script>

<style></style>
